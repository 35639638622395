import { AxiosRequestConfig } from "axios";
import { FirebaseAuth } from "../services/firebase/Firebase";

// export const URL = "http://localhost:4000";
// export const URL = "http://localhost";
// export const URL = "http://192.168.188.74";
// export let URL = "https://camp24checkin.de";
// export const URL = "https://camp24.noviente.xyz";
export const URL = "https://demo.camp24.xyz";
// if (process.env.Node_ENV !== "production") {
// URL = "http://localhost";
// }

async function authorizationHeader() {
  if (FirebaseAuth.currentUser) {
    let token = await FirebaseAuth.currentUser.getIdTokenResult();
    return "Bearer " + token.token;
  }
  return "";
}

export async function callConfigGet(path: string, params?: any): Promise<AxiosRequestConfig> {
  const callConfig: AxiosRequestConfig = {
    method: "GET",
    url: URL + path,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: await authorizationHeader(),
    },
  };

  if (params) {
    callConfig.params = params;
  }

  return callConfig;
}

export async function callConfigPut(
  path: string,
  data?: any,
  contentType: string = "application/json"
): Promise<AxiosRequestConfig> {
  const authorization = await authorizationHeader();
  const callConfig: AxiosRequestConfig = {
    method: "PUT",
    url: URL + path,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: authorization,
    },
  };
  if (data) {
    callConfig.data = data;
    callConfig.headers = { ...callConfig.headers, "Content-Type": contentType };
  }
  return callConfig;
}

export async function callConfigPost(
  path: string,
  data?: any,
  contentType: string = "application/json"
): Promise<AxiosRequestConfig> {
  const authorization = await authorizationHeader();
  const callConfig: AxiosRequestConfig = {
    method: "POST",
    url: URL + path,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: authorization,
    },
  };
  if (data) {
    callConfig.data = data;
    callConfig.headers = { ...callConfig.headers, "Content-Type": contentType };
  }
  return callConfig;
}

export async function callConfigDelete(path: string): Promise<AxiosRequestConfig> {
  return {
    method: "DELETE",
    url: URL + path,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: await authorizationHeader(),
    },
  };
}
